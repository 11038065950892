.diary-navbar {
  /* stick to bottom navbar */
  position: sticky;
  bottom: 0;
  padding: 0 2%;
  z-index: 12;
}

.diary-navbar .ant-menu-item {
  width: 24%;
  justify-content: center;
  display: flex;
}

.diary-navbar :not(.ant-menu-item-selected).ant-menu-item {
  color: #9fc2d7;
}

.diary-navbar .ant-menu-item-selected.ant-menu-item {
  color: #376bed;
}
